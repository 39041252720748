import appRoutes from './appRoutes'

import { resolve } from '~/utils/url.utils'

const paths = appRoutes

const leadsApisPaths = {
  LEAD_REQUEST_CONTRACT: '/api/leads/$leadId/request-contract',
  LEAD_GENERATE_PROPOSAL: '/api/leads/$leadId/generate-proposal',
  LEAD_CHECK_SIGNATURE: '/api/leads/$leadId/check-signature',
  LEAD_UPDATE_BILL_DISCO: '/api/leads/$leadId/update-bill-disco',
} as const

export const apiPaths = {
  GENERATE_SIGNED_S3_URL_BILL: '/api/generate-signed-s3-url-bill',
  GENERATE_SIGNED_S3_URL_DOCUMENT: '/api/generate-signed-s3-url-document',
  SEARCH_CEP: '/api/cep',
  POSTHOG_SIGNED: '/api/posthog-signed',
  REPORT: '/api/report',
  PROCESS_DOCUMENT: '/api/process-document',
  CHECK_SIGNATURE: '/api/signature-ready',
  ELIGIBLE_DISCOS: '/api/active-discos',
  ...leadsApisPaths,
} as const

export type RoutePathsKeys = keyof typeof paths

export const pathReplace = resolve

export default paths
